@tailwind base;
@tailwind components;
@tailwind utilities;

/* In your global CSS file */
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

  @media (max-width: 768px) {
    header {
      background-position: center 20%;  
    }
  }
  

  .scheduler-container {
    height: 24rem; /* Adjust this value to fit the modal size */
    overflow: auto; /* Correct syntax */
  }
  
  .scheduler-container .scheduler-table {
    font-size: 0.875rem; /* Tailwind's 'text-sm' */
  }
  
  .scheduler-container .scheduler-content {
    height: 100%;
  }
  
  .scheduler-container .scheduler-header {
    padding: 0.5rem 0.25rem; /* Tailwind's 'py-2 px-1' */
  }
  
  .scheduler-container .scheduler-view {
    margin: 0;
    padding: 0;
  }
  
  .aspect-w-1 {
    aspect-ratio: 1 / 1;
  }
  
  .aspect-h-1 {
    aspect-ratio: 1 / 1;
  }